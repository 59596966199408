'use client'

import NextLink, {LinkProps as NextLinkProps} from 'next/link'
import {ComponentPropsWithoutRef, useMemo} from 'react'

import {usePathname} from 'next/navigation'

export function checkIsExternal(href: string) {
	return !!href && href.startsWith('http')
}

export function checkIsFile(href: string) {
	return !!href && href.split('/').pop()?.includes('.')
}

export type LinkProps = ComponentPropsWithoutRef<'a'> &
	Omit<NextLinkProps, 'href'> & {href?: string}

export const Link: React.FC<LinkProps> = ({href, target, as, ...props}) => {
	const pathName = usePathname()
	const hash = useMemo(() => {
		if (!href) return false
		if (href.startsWith('internal:#')) return href.replace('internal:', '')
		if (href.startsWith('#')) return href
		if (href.indexOf('#') > 0 && href.replace(/[#?&].*/g, '') === pathName)
			return href.replace(/.*([#].*)/g, '$1')
		return false
	}, [href, pathName])
	if (!href && !props.onClick) return <div {...(props as any)} />

	const isExternal = href && checkIsExternal(href)
	const isFile = href && checkIsFile(href)

	if (!href || isExternal || isFile)
		return (
			<a
				{...props}
				href={href}
				target={target || '_blank'}
				rel={isExternal ? 'external nofollow noopener' : undefined}
				onClick={(e) => {
					if (props.onClick) props.onClick(e)
				}}
			/>
		)

	return (
		<NextLink
			{...props}
			target={target || '_self'}
			href={hash ? hash : href}
			onClick={(e) => {
				if (hash) {
					e.preventDefault()
					scrollToHash(hash)
					history.pushState(null, null, hash)
					if (props.onClick) props.onClick(e)
					return false
				}
				if (props.onClick) props.onClick(e)
			}}
		/>
	)
}

export function scrollToHash(to, yOffset = -60) {
	if (!to) return

	const hash = to.substring(0, 1) === '#' ? to.substring(1) : to
	if (!hash) return

	const el: HTMLElement | null = document.getElementById(hash)
	if (!el) return
	const y = el.getBoundingClientRect().top + window.scrollY + yOffset

	setTimeout(() => window.scrollTo({top: y, behavior: 'smooth'}), 0)
}
