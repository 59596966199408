import {DPNode} from 'types/DPTypes'
import {nl} from 'locale/nl'

export const en: typeof nl = {
	search: {
		all: 'All',
		categories_label: 'Filter results:',
		categories: {
			press: 'Press',
			article: 'Articles',
			job: 'Jobs',
			event: 'Events'
		}
	},
	getNodeLabel: (node: DPNode): string => {
		switch (node._type) {
			case 'page':
				return 'Page'
			case 'product':
				return 'Product'
			case 'event':
				return 'Event'
			case 'job':
				return 'Job'
			case 'press':
				return 'Press'
			case 'research_project':
				return 'imec.icon'
			case 'dossier':
				return 'Dossier'
			case 'video_page':
				return 'Video'
			case 'podcast':
				return 'Podcast'
			case 'case':
				return node.label?.name
			case 'article':
				return node.label?.name
			default:
				return ''
		}
	},
	read_more: 'Show more',
	read_more_arialabel: 'Read more about ',
	agenda: {
		no_results: 'There are currently no events scheduled on our agenda',
		no_results_past: 'There are currently no past events',
		readmore: 'Show more'
	},
	presslabel: 'Press',
	iconproject: {
		read_more: 'Show more'
	},
	footer: {
		follow: 'Follow imec on:',
		follow_iclink: 'Follow imec.IC-link on:',
		follow_nanoic: 'Follow the NanoIC project on:',
		more_imec: 'More imec',
		hyperspectral: '<span>Hyperspectral imaging</span>, a part of',
		iclink: '<span>imec.IC-link</span>, a part of',
		nanoic: '<span>imec.IC-link</span>, a part of'
	},
	newsletter: {
		placeholder: 'Your email address',
		placeholder_success: 'Thanks for your subscription!',
		signup: 'Sign up'
	},
	jobs: {
		loadmore: 'LOAD MORE',
		all: 'All',
		clear_refinements: 'Clear all',
		jobs_saved: 'jobs saved',
		job_saved: 'job saved',
		periods: {
			past_day: 'Past day',
			past_3days: 'Past 3 days',
			past_week: 'Past week',
			past_month: 'Past month'
		},
		filters: {
			type: 'Category',
			categories: 'Application domain',
			job_title: 'Title',
			region: 'Location',
			employment_type: 'Employment Types',
			employer: 'Employer',
			promotor: 'Promotor',
			csr: 'CSR-label',
			competence: 'Competence',
			marketsegments: 'Market segments',
			date_posted: 'Date Posted'
		},
		alerts: {
			button: 'More opportunities like this? Let me know!',
			email: 'E-mail address',
			agree_terms:
				'Yes, I agree that imec sends me job alerts and relevant information about working at imec.',
			intro:
				'Based on your current search and/or job filter(s), we will notify you about future matching opportunities.',
			thank_you: 'Thank you for subscribing. Your preferences are saved.',
			activate: 'Activate',
			errors: {
				email: 'No valid email address provided',
				terms: 'Please agree to the terms to continue',
				filter: 'Please select at least 1 filter to apply.'
			}
		}
	},
	readingroom: {
		popular_topics: 'Popular topics',
		related: 'Partner related',
		clear: 'Clear filters',
		searchbar: 'Search by subject or theme',
		showmore: 'Subjects',
		showless: 'Show less',
		readmore: 'SHOW MORE',
		noResultsTitle: 'Shame!',
		noResultsPart1: 'We cant find any results for ',
		noResultsPart2:
			'. Please try again with a broader search query. Or press ‘subjects’ to filter based on a theme.',
		extra: {
			block: {
				name: 'Over imec',
				items: [
					{
						type: 'tag' as const,
						name: 'Algemeen'
					},
					{
						type: 'pagetype' as const,
						pagetype: 'press',
						name: 'Press releases'
					},
					{
						type: 'partner_related' as const,
						name: 'Partner gerelateerd'
					},
					{
						type: 'tag' as const,
						name: 'Expertise'
					},
					{
						type: 'label' as const,
						name: 'Featured in the media'
					}
				]
			},
			blocks: {
				name: 'Extra category label',
				items: [
					{
						type: 'pagetype' as const,
						pagetype: 'press',
						name: 'Persberichten'
					},
					{
						type: 'tag' as const,
						name: 'DUMMY [tag] Child'
					},
					{
						type: 'partner_related' as const,
						name: 'Partner related'
					},
					{
						type: 'label' as const,
						name: 'Featured in the media'
					}
				]
			},
			vlaanderen: {
				name: 'About imec',
				items: [
					{
						type: 'tag' as const,
						name: 'General'
					},
					{
						type: 'pagetype' as const,
						pagetype: 'press',
						name: 'Persberichten'
					},
					{
						type: 'partner_related' as const,
						name: 'Partner related'
					},
					{
						type: 'tag' as const,
						name: 'Duurzame ontwikkeling'
					},
					{
						type: 'label' as const,
						name: 'In de media'
					}
				]
			},
			corporate: {
				name: 'About imec',
				items: [
					{
						type: 'tag' as const,
						name: 'General'
					},
					{
						type: 'pagetype' as const,
						pagetype: 'press',
						name: 'Press releases'
					},
					{
						type: 'partner_related' as const,
						name: 'Partner related'
					},
					{
						type: 'tag' as const,
						name: 'Sustainable development'
					},
					{
						type: 'label' as const,
						name: 'Featured in the media'
					}
				]
			}
		},
		textualTags: ['DUMMY [tag]'],
		types: {
			article: 'Blog',
			press: 'Press release',
			video_page: 'Video',
			podcast: 'Podcast'
		},
		tabs: {
			all: 'Overview',
			article: 'Articles',
			press: 'Press releases',
			video_page: 'Videos',
			dossier: 'Dossiers',
			podcast: 'Podcasts'
		},
		popular: {title: 'Most viewed'}
	},
	header: {
		search: {
			toggle_arialabel: 'Toggle search',
			search_arialabel: 'Search'
		},
		hamburger: {
			open_arialabel: 'Open mobile menu',
			close_arialabel: 'Close mobile menu'
		},
		request: 'Request more information',
		back: 'Back to overview'
	},
	article: {
		back: 'To Stories',
		short: 'Summary',
		readmore: 'Discover more'
	},
	press: {
		title: 'Press release',
		partner_related: 'Partner related news',
		readmore: 'Read more'
	},
	sidebar: {
		mobile_arialabel: 'Mobile number',
		phone_arialabel: 'Telephone number',
		email_arialabel: 'Mail to',
		tags: 'More about these topics',
		date: 'Published on'
	},
	form: {
		firstname: 'First name',
		lastname: 'Last name',
		email: 'E-mail',
		phone: 'Phone number',

		company: 'Company/organisation',
		address: 'Address company',
		zipcode: 'Zip code',
		city: 'City',

		subject: 'Subject',
		message: 'Your message',
		submit: 'Send',
		thanks: {
			title: 'Thanks!',
			text: 'The form was submitted successfully'
		}
	},
	request: {
		title: 'How can we help you?',
		identification: 'Send us your request',
		identification_arialabel:
			'Send us your request: Choose your organization type',
		topics: `I'm looking for`,
		topics_arialabel: "I'm looking for: Select a topic",
		contact: 'Contact us'
	},
	iconoverview: {
		startyear: 'Start year',
		market: 'Market',
		projectType: 'Research project type',
		status: 'status',
		readmore: 'read more',
		filters: {
			market: 'Market',
			research_project_type: 'Research project type',
			status: 'Status',
			year: 'Start year'
		}
	},
	filters: {
		clear: 'remove filters'
	},
	downloads: {
		title: 'Download',
		text: 'Download file'
	},
	iframe: {
		invisible:
			'This content is only visible on the desktop version of this website.'
	},
	researchproject: {
		about: 'About this project'
	},
	job: {
		apply: 'Apply',
		form: {
			title: 'Send this job to your email',
			thanks: 'Email has been sent'
		}
	},
	words: {
		show_all: 'Show all'
	},
	jobalerts: {
		title: 'Job alerts',
		unsubscribed: {
			title: 'Unsubscribed',
			text: 'We canceled the job alerts for these criteria:',
			empty: 'All job alerts were canceled',
			view: {
				job: 'View careers',
				academic: 'View opportunities'
			},
			add: 'Add new job alert'
		}
	},
	share: 'Share this article on',
	social: {
		facebook: 'Facebook',
		linkedin: 'LinkedIn',
		twitter: 'X'
	},
	cookies: {
		placeholder: {
			text: (category) => `Accept ${category}-cookies to view this content.`,
			button: 'Cookie settings'
		}
	},
	gallery: {
		image_arialabel: 'Download/view image'
	},
	carousel: {
		index_arialabel: 'Carousel: Go to specific index',
		prev_arialabel: 'Carousel: Go to previous',
		next_arialabel: 'Carousel: Go to next'
	},
	select: 'Select: ',
	eloqua: {
		iframe_arialabel: 'Registration form'
	},
	dossier: 'Dossier',
	euprojects: {
		title: 'Public funded projects',
		domain: 'Expertise domain',
		filters: {
			domain: 'Expertise domain',
			scope: 'EU/Flemish'
		},
		scopes: {
			europe: 'EU',
			flemish: 'Flemish'
		}
	}
}
