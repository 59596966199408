'use client'

import * as langs from 'locale'

import {Component, createContext, useContext} from 'react'

import {drupalLang} from './locale'
import {nl} from 'locale/nl'

export const I18nContext = createContext<{language: string; data: any}>({
	language: drupalLang,
	data: langs[drupalLang]
})

export class I18n<T> extends Component<{
	language: string
	data: T
	children: React.ReactNode
}> {
	render() {
		return (
			<I18nContext.Provider value={this.props}>
				{this.props.children}
			</I18nContext.Provider>
		)
	}
}

export const useTranslation = () => {
	const {data} = useContext(I18nContext)
	return data as typeof nl
}
