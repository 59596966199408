'use client'

import React, {FunctionComponent, HTMLProps} from 'react'

import {Icon} from 'assets/icon'
import {Mod} from 'util/style'
import {Trusted} from 'util/trusted'
import {Link as UtilLink} from 'util/link'
import {createTheme} from 'util/createtheme'
import css from './theme.module.scss'
import {fromModule} from 'util/styler/Styler'

type DOMAttrs = any

const styles = fromModule(css)

/*const styles = styler('theme', [
	'outercontainer',
	'container',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'p',
	'wysiwyg',
	'trustedtext',
	'button',
	'buttontext',
	'buttonicon',
	'link',
	'linktext',
	'linkicon'
])*/

const OuterContainer: FunctionComponent<
	{mod?: Mod<'big'>} & HTMLProps<HTMLDivElement>
> = ({mod, ...props}) => (
	<div
		{...props}
		className={styles.outercontainer.mod(mod).mergeProps(props)()}
	/>
)

const Container: FunctionComponent<
	{fullwidth?: boolean} & HTMLProps<HTMLDivElement>
> = ({children, fullwidth = false, ...props}) => {
	return (
		<div
			{...props}
			className={styles.container.mod({fullwidth}).mergeProps(props)()}
		>
			{children}
		</div>
	)
}

type Header = FunctionComponent<
	{
		as?: any
		mod?: Mod<'light' | 'medium' | 'blueline' | 'arrow' | 'toggle' | 'white'>
	} & HTMLProps<HTMLHeadingElement>
>

const H1: Header = ({as: Tag = 'h1', mod, children, ...props}) => (
	<div {...props}>
		<Tag className={styles.h1.mod(mod)()}>{children}</Tag>
	</div>
)
const H2: Header = ({as: Tag = 'h2', mod, children, ...props}) => (
	<div {...props}>
		<Tag className={styles.h2.mod(mod)()}>{children}</Tag>
	</div>
)
const H3: Header = ({as: Tag = 'h3', mod, children, ...props}) => (
	<div {...props}>
		<Tag className={styles.h3.mod(mod)()}>{children}</Tag>
	</div>
)
const H4: Header = ({as: Tag = 'h4', mod, children, ...props}) => (
	<div {...props}>
		<Tag className={styles.h4.mod(mod)()}>{children}</Tag>
	</div>
)
const H5: Header = ({as: Tag = 'h5', mod, children, ...props}) => (
	<div {...props}>
		<Tag className={styles.h5.mod(mod)()}>{children}</Tag>
	</div>
)

type P = FunctionComponent<HTMLProps<HTMLParagraphElement>>

const P: P = (props) => (
	<p {...props} className={styles.p.mergeProps(props)()} />
)

const Wysiwyg: FunctionComponent<
	{
		children: string
		mod?: Mod<'inherit' | 'title-line'>
	} & DOMAttrs
> = (props) => (
	<Trusted
		{...props}
		className={styles.wysiwyg
			.with('wysiwyg')
			.mod(props.mod)
			.mergeProps(props)()}
	/>
)

const TrustedText: FunctionComponent<{children: string} & DOMAttrs> = (
	props
) => <Trusted {...props} className={styles.trustedtext.mergeProps(props)()} />

type ButtonProps =
	| ({as: 'button'} & HTMLProps<HTMLButtonElement>)
	| HTMLProps<HTMLAnchorElement>

type Button = ButtonProps & {
	href?: string
	mod?: Mod<'outline' | 'rounded' | 'round' | 'white'>
}

const Button: FunctionComponent<Button> = (props) => {
	let Comp = UtilLink as any
	if (props?.as === 'button') Comp = 'button'

	return (
		<Comp
			{...props}
			href={props.href}
			className={styles.button.mod(props.mod).mergeProps(props)()}
		>
			{props.children}
		</Comp>
	)
}

type Span = HTMLProps<HTMLSpanElement>

const ButtonText: FunctionComponent<Span> = (props) => (
	<span {...props} className={styles.buttontext.mergeProps(props)()} />
)

const ButtonIcon: FunctionComponent<Span> = () => (
	<span className={styles.buttonicon()}>
		<Icon icon="ArrowRight" />
	</span>
)

export type ThemeLinkType = HTMLProps<HTMLAnchorElement> & {
	href?: string
	mod?: Mod<'inherit'>
}

const Link: React.FC<ThemeLinkType> = ({
	children,
	href,
	target,
	className,
	mod,
	...rest
}) => {
	const Comp = UtilLink as any
	return (
		<Comp
			{...rest}
			href={href}
			className={styles.link.with(className).mod(mod)()}
		>
			{children}
		</Comp>
	)
}

const LinkText: FunctionComponent<Span> = (props) => (
	<span {...props} className={styles.linktext.mergeProps(props)()} />
)

const LinkIcon: FunctionComponent<Span> = () => (
	<span className={styles.linkicon()}>
		<Icon icon="ArrowRight" />
	</span>
)

export const defaultTheme = {
	OuterContainer,
	Container,
	H1,
	H2,
	H3,
	H4,
	H5,
	P,
	Wysiwyg,
	TrustedText,
	Button,
	ButtonText,
	ButtonIcon,
	Link,
	LinkText,
	LinkIcon
}

export const Theme = createTheme(defaultTheme)
